<template>
  <div class="col-xl-12">
    <div class="row">
      <div class="col-lg-12">
        <div class="EquipPointDetail bg-white">
          <div class="EquipPointDetailTitle">
            □ {{$t('설비 관제점 설정')}}
          </div>
          <div class="EquipPointDetailTable" v-if="nodeData !== null">
            <div>
              <div class="row">
                <b-col cols="4">
                  <b-form-group label-cols="4" label-cols-lg="4" content-cols="8" content-cols-lg="8" :label="$t('설비 기기')"
                    label-for="input-default">

                    <b-form-input v-model="nodeData.equipName" disabled> </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="4"> </b-col>
                <b-col cols="4">
                  <point-detail-btn @update="onModify" @delete="onRemove"></point-detail-btn>
                </b-col>
              </div>
              <div v-if="nodeTableData !== null">
                <point-detail-table :items="nodeTableData"></point-detail-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import backEndApi from "../../../api/backEndApi";

import pointDetailTable from "./EquipPointDetailTable.vue";
import pointDetailBtn from "./EquipPointDetailBtn.vue";
import AppErrorHandler from "@src/appErrorHandler";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
  name: "EquipPointDetail",
  props: ["nodeData"],
  components: {
    pointDetailTable,
    pointDetailBtn,
  },
  data() {
    return {
      nodeTableData: null,
      popupMessages
    };
  },
  watch: {
    async nodeData() {
      this.nodeTableData = await this.getEquipMapList(this.nodeData);
    },
  },
  methods: {
    async getEquipMapList(nodeData) {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        let result = await backEndApi.equipMap.getEquipMapList(nodeData.equipIdx, nodeData.dvsnCode);
        for (let i = 0; i < result.data.length; i++) {
          if (result.data[i].dvsnCode === null) {
            result.data[i].dvsnCode = nodeData.dvsnCode;
          }
        }
        return result.data;
      } catch (err) {
        new AppErrorHandler(err)
          .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
          .errHandling();
      }
    },
    async onModify() {
      // console.log(this.nodeTableData)
      let param = [];

      for (let i = 0; i < this.nodeTableData.length; i++) {
        // console.log(this.nodeTableData[i]);
        if (this.nodeTableData[i].ptAddr !== null) {
          param.push({
            equipIdx: this.nodeTableData[i].equipIdx,
            dvsnCode: this.nodeTableData[i].dvsnCode,
            equipPropCode: this.nodeTableData[i].equipPropCode,
            ptAddr: this.nodeTableData[i].ptAddr,
            ptIdx: this.nodeTableData[i].ptIdx,
            mapDetail: this.nodeTableData[i].mapDetail,
          });
        }
      }

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        await backEndApi.equipMap.insertEquipMap(param);
        this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);

      } catch (err) {
        new AppErrorHandler(err)
          .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
          .errHandling();
      }

    },
    async onRemove() {
      let param = [];

      for (let i = 0; i < this.nodeTableData.length; i++) {
        if (this.nodeTableData[i].checked === true) {
          param.push({
            equipIdx: this.nodeTableData[i].equipIdx,
            dvsnCode: this.nodeTableData[i].dvsnCode,
            equipPropCode: this.nodeTableData[i].equipPropCode,
            ptAddr: this.nodeTableData[i].ptAddr,
            ptIdx: this.nodeTableData[i].ptIdx,
            mapDetail: this.nodeTableData[i].mapDetail,
          });
        }
      }

      if (param.length === 0) {
        this.alertNoti(popupMessages.EQUIP_POINT_CONTROL_TYPE_DELETE_SELECT_POPUP_MESSAGE);
        return;
      }

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        await backEndApi.equipMap.deleteEquipMap(param);
        this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
        this.nodeTableData = await this.getEquipMapList(this.nodeData);
      } catch (err) {
        new AppErrorHandler(err)
          .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
          .errHandling();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.EquipPointDetail {
  border: 1px solid #313131;
  border-radius: 5px;

  .EquipPointDetailTitle {
    padding: 15px;
    font-size: 15px;
    font-weight: bold;
  }

  .EquipPointDetailTable {
    padding: 15px;
    margin: 5px;
    border: 1px solid #000000;
  }
}
</style>
