<template>
  <div class="col-xl-12">
    <div class="row">
      <div class="col-lg-12">
        <div class="EquipPointList bg-white">
          <div class="EquipPointListTitle">
            □ {{$t('계통/설비 조회')}}
          </div>
          <div class="EquipPointListTree">
            <panel :variant="panelVariant" noButton="true" bodyClass="p-0" headClass="hide bg-white">
              <tree ref="treeList" v-if="Array.isArray(equipSystemData) && equipSystemData.length > 0" :data="equipSystemList" @node:selected="onNodeSelected" />
            </panel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LiquorTree from "liquor-tree";

import backEndApi from "../../../api/backEndApi.js";
import AppErrorHandler from "@src/appErrorHandler";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
  name: "EquipPointList",
  components: {
    [LiquorTree.name]: LiquorTree,
  },
  data() {
    return {
      equipSystemData: null,
      popupMessages
    };
  },
  computed: {
    equipSystemList() {
      return this.setTreeData(this.equipSystemData);
    },
  },
  async created() {
    this.equipSystemData = await this.getEquipSystemList();
  },
  methods: {
    async getEquipSystemList() {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        let result = await backEndApi.equipMap.getEquipSystemList();
        return result.data;
      } catch (err) {
        new AppErrorHandler(err)
          .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
          .errHandling();
      }
    },

    setTreeData(result) {
      let options = [
        {
          text: global.xe.$t("전체 계통"),
          children: [],
          state: {
            expanded: false,
            selectable: false,
          },
        },
      ];
      if (result !== null) {
        options[0].state.expanded = true;
        result.forEach((item) => {
          let systemOption = {};
          systemOption.text = item.systemName;
          systemOption.children = [];
          systemOption.data = item;
          systemOption.state = {
            expanded: false,
            selectable: false,
          };

          if (item.equipList.length !== 0) {
            // systemOption.state.expanded = true;
            for (let i = 0; i < item.equipList.length; i++) {
              systemOption.children.push({
                text: item.equipList[i].equipName,
                data: item.equipList[i],
              });
            }
          }

          options[0].children.push(systemOption);
        });
      }

      return options;
    },
    onNodeSelected(node) {
      this.$emit("nodeSelect", node.data);
    },
  },
};
</script>

<style lang="scss">
.EquipPointList {
  border: 1px solid #313131;
  border-radius: 5px;
  .EquipPointListTitle {
    padding: 15px;
    font-size: 15px;
    font-weight: bold;
  }
  .EquipPointListTree {
    padding: 5px;
    margin: 5px;
    border: 1px solid #000000;
  }
}
</style>
