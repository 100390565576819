<template>
			<div class="row p-l-15 p-b-10">
				<div class="col-3">
					<div class="row">
						<div class="col-4 text-right d-flex" :style="{ justifyContent: 'end' }"><span :style="{ fontSize: '13px', fontWeight: 'bold', alignSelf: 'center' }">{{$t('구역')}}</span></div>
						<div class="col-8">
							<tree-select
								v-model="searchData.zoneIdx"
								:load-options="loadOptions"								
								:multiple="false"
								:placeholder="$t(controlMessages.ZONE_TREE_PLACEHOLDER_MESSAGE)"
								:noChildrenText="$t(controlMessages.ZONE_TREE_NOCHILDREN_MESSAGE)"
								:noResultsText="$t($t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE))"
								:options="detailZoneList"
								:clear-on-select="true"/>
						</div>
					</div>
				</div>
				<div class="col-3">
					<div class="row">
						<div class="col-4 text-right d-flex" :style="{ justifyContent: 'end' }"><span :style="{ fontSize: '13px', fontWeight: 'bold', alignSelf: 'center' }">{{$t('용도')}}</span></div>
						<div class="col-8">
							<b-form-select v-model="searchData.useage" :options="useageList"></b-form-select>
							<!-- <tree-select :load-options="loadOptions" v-model="searchData.useage" :multiple="false" :options="detailZoneList" /> -->
						</div>
					</div>
				</div>
				<div class="col-4">
					<div class="row">
						<div class="col-4 text-right d-flex" :style="{ justifyContent: 'end' }"><span :style="{ fontSize: '13px', fontWeight: 'bold', alignSelf: 'center' }">{{$t('관제점명')}}</span></div>
						<div class="col-8">
							<valid-input :inputType="'text'" :inputValue.sync="searchData.pointName" :NonStatus="true" @enterEvents="onSearch"></valid-input>

							<!-- <b-form-input v-model="searchData.pointName" @keydown.enter="onSearch"></b-form-input> -->
							<!-- <tree-select :load-options="loadOptions" v-model="searchData.useage" :multiple="false" :options="detailZoneList" /> -->
						</div>
					</div>
				</div>
				<div class="col-2 text-right">
					<div class="row" :style="{ justifyContent: 'center' }">
						<b-button @click="onSearch">{{$t('조회')}}</b-button>
					</div>
				</div>
				<!-- <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
        <div class="pt-2 p-l-15">
          <span>사용여부</span>
        </div>
        <div class="p-l-5">
          <select class="form-control" ref="enabled" @change="onChangeCombo">
            <option value="">전체</option>
            <option value="Y">사용</option>
            <option value="N">사용안함</option>
          </select>
        </div>
      </div>
      <div class="font-weight-bold d-flex flex-row p-l-5" :style="{ fontSize: '15px' }">
        <button class="form-control" @click="onSearch">조회</button>
      </div> -->
			</div>
</template>

<script>
	import backEndApi from "../../../api/backEndApi";
	import AppErrorHandler from "@src/appErrorHandler";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import * as controlMessages from "@src/consts/controlMessageConsts";

	export default {
		name: "EquipPointModalSearch",
		data() {
			return {
				searchData: {
					zoneIdx: null,
					useage: null,
					enabled: null,
					pointName: null,
				},
				detailZoneList: null,
				useageList: null,
				popupMessages,
				controlMessages
			};
		},
		async created() {
			this.detailZoneList = await this.getZoneListHierarchi();
			this.useageList = await this.getSmallCodeList();
			this.onSearch();
		},
		methods: {
			onSearch() {
				if (this.searchData.zoneIdx === undefined) {
					this.searchData.zoneIdx = null;
				}

				this.$emit("search", this.searchData);
			},
			async getSmallCodeList() {


				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
					↓↓↓↓↓
				*/
				try {
					let result = await backEndApi.publicCode.getSmallCodeList("itemUseageCode", null, "Y");
					let options = [];

					for (let i = 0; i < result.data.length; i++) {
						let option = {
							value: result.data[i].smallCode,
							text: result.data[i].smallCodeName,
							data: result.data[i],
						};

						options.push(option);
					}

					return options;
				} catch (err) {
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.errHandling();
				}
			},

			async getZoneListHierarchi() {

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
					↓↓↓↓↓
				*/
				try {
					let result = await backEndApi.zoneInfo.getZoneListHierarchi();
					let options = [];
					let temp = [];
					temp.push(result.data);
					options = this.setZoneTreeOption(temp);
					return options;
				} catch (err) {
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.errHandling();
				}
			},
			setZoneTreeOption(resultData) {
				resultData.forEach((data) => {
					data.id = data.zoneIdx;
					data.label = data.zoneName;

					if (data.children.length === 0) {
						delete data.children;
					}

					if (data["children"]) {
						return this.setZoneTreeOption(data["children"]);
					} else {
						return data;
					}
				});

				return resultData;
			},

			loadOptions({ callback }) {
				callback();
			},
		},
	};
</script>

<style></style>
