<template>
  <div class="col-xl-12">
    <div class="row">
      <div class="col-lg-12">
        <div class="pull-left">
          <h3>
            {{$t('운영 기준정보 관리')}} > {{$t('설비 관리')}} > {{$t('설비 관제점 설정')}}
          </h3>
        </div>
      </div>
    </div>

    <hr class="mt-3 mb-3" />

    <div class="row">
      <div class="col-4">
        <point-list @nodeSelect="onSelect"></point-list>
      </div>
      <div class="col-8">
        <point-detail :nodeData="nodeData"></point-detail>
      </div>
    </div>

    
  </div>
</template>

<script>
// NOTE - 설비 관리 > 설비 관제점 설정

import pointList from "./EquipPointList.vue";
import pointDetail from "./EquipPointDetail.vue";

export default {
  name: "EquipDevice",
  props: [],
  components: {
    pointList,
    pointDetail
  },
  watch: {},
  data() {
    return {
      nodeData: null,
    };
  },
  created() {},
  mounted() {},
  methods: {
    onSelect(data){
      this.nodeData = data;
    },
    onSearch(data) {
      this.items = data;
    },
    newModal() {
      this.$refs.newModal.show();
    },
    hide() {
      this.$refs.newModal.hide();
    },
    reSearch() {
      this.$refs.search.reSearch();
    },
  },
};
</script>

<style scoped></style>
