<template>
	<div class="EquipPointDetailTable">
		<b-table
			head-variant="dark"
			show-empty
			hover
			responsive
			ref="EquipPointDetailTable"
			:style="'min-width:100%;'"
			:class="'text-center mb-0 rounded'"
			:items="tableData"
			:fields="tableField"
			sticky-header="690px"
			:empty-text="items.message"
			table-variant="light"
			select-mode="multi"
			selectable
			@row-clicked="onRowDetail"
		>
			<!-- @row-clicked="onRowClicked" -->
			<!-- <template #empty="">
        <h4>{{ '조회자료 없음' }}</h4>
      </template> -->

			<!-- <template v-slot:cell(sesnDvsnCode)="row"  v-for="(sesnDvsnCode, index) in tableCode.sesnDvsnCodes" :key="index" :value="sesnDvsnCode">

      </template> -->
			<template #cell(checked)="row">
				<b-form-checkbox v-model="row.item.checked" @change="isCheckService(row.item.checked, row)"></b-form-checkbox>
			</template>

			<template #cell(equipPropCode)="row">
				{{ row.item.equipPropCode }}
				<!-- <b-form-input v-model="row.item.equipPropCode" disabled></b-form-input> -->
			</template>

			<template #cell(equipPropName)="row">
				{{ row.item.equipPropName }}
				<!-- <b-form-input v-model="row.item.equipPropName" disabled></b-form-input> -->
			</template>

			<template #cell(ptName)="row">
				<button class="form-control" @click="setPointList(row)">
					<div>{{ row.item.ptName }}</div>
				</button>
				<!-- <b-form-input type="button" ref="ptName" v-model="row.item.ptName" @click="test">
        </b-form-input> -->

				<!-- <b-form-select v-model="row.item.enabled" :options="tableUsegeOptions"></b-form-select> -->
			</template>

			<template #cell(mapDetail)="row">
				<valid-input :inputType="'text'" :inputValue.sync="row.item.mapDetail" :rules="rules.EQUIP_POINT_DETAIL_RULE" :NonStatus="true"></valid-input>

				<!-- <b-form-input type="number" v-model="row.item.sortOrder"></b-form-input> -->
			</template>

			<template #empty="scope">
				<div>
					{{ scope.emptyText }}
				</div>
			</template>
			<!--
      <template #cell(holiTypeCode)="row">
        <select class="form-control" ref="holiTypeCode" v-model="row.item.holiTypeCode">
          <option v-for="(code, index) in holiTypeCodeList" :key="index" :value="code.smallCode">
            {{ code.smallCodeName }}
          </option>
        </select>
      </template>

      <template #cell(baseDate)="row">
        <b-form-datepicker :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
          viewMode= 'days' locale= 'ko' 
          v-model="row.item.baseDate" class="mb-2 text-left">
        </b-form-datepicker>
      </template>

      <template #cell(holiDesc)="row">
        <input class="form-control text-left" v-model= "row.item.holiDesc"/> 
      </template>

      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span class="align-middle" aria-hidden="true">&check;</span>
          <span class="sr-only align-middle">Selected</span>
        </template>
        <template v-else>
          <span class="align-middle" aria-hidden="true">&nbsp;</span>
          <span class="sr-only align-middle">Not selected</span>
        </template>
      </template>       -->
		</b-table>
		<!-- <div class="rowInsertButton">
      <b-button @click="isRowInsert">행 추가</b-button>
    </div> -->
		<b-modal id="pointListModal" size="xl" centered hide-footer :title="$t(`관제점 리스트`)">
			<div>
				<point-list-search @search="onSearch"></point-list-search>
				<b-table
					head-variant="dark"
					show-empty
					hover
					hide
					ref="PointListTable"
					:style="'min-width:100%;'"
					:class="'text-center mb-0 rounded'"
					:items="pointTableFilering === null ? pointTableItem : pointTableFilering"
					:fields="pointTableField"
					sticky-header="400px"
					:empty-text="$t('데이터가 존재하지 않습니다.')"
					table-variant="light"
					select-mode="single"
					selectable
					@row-clicked="pointListSelect"
				>
				</b-table>
				<!-- <div v-for="(data, i) in pointTableItem" :key="`key${i}`">
          {{ data.ptAddr }}
          {{ data.ptName }}
          {{ data.itemUseageName }}
          {{ data.energySourceName }}
          {{ data.applyZoneName }}
        </div> -->
			</div>
		</b-modal>
	</div>
</template>

<script>
	import backEndApi from "@src/api/backEndApi";

	import pointListSearch from "./EquipPointModalSearch.vue";
	import AppErrorHandler from "@src/appErrorHandler";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		name: "EquipPointDetailTable",
		props: ["items", "equipTypeCode"],
		components: {
			pointListSearch,
		},
		data() {
			return {
				ptSelectRow: null,
				tableField: null,
				tableCheckbox: [],
				tableUsegeOptions: [
					{ value: "Y", text: global.xe.$t("사용") },
					{ value: "N", text: global.xe.$t("사용 안함") },
				],
				pointTableFilering: null,
				pointTableItem: null,
				pointTableField: [
					{ key: "ptAddr", label: global.xe.$t("관제점 주소") },
					{ key: "ptName", label: global.xe.$t("관제점명") },
					{ key: "itemUseageName", label: global.xe.$t("용도") },
					{ key: "energySourceName", label: global.xe.$t("에너지원") },
					{ key: "applyZoneName", label: global.xe.$t("구역") },
				],
				rules,
				popupMessages
			};
		},
		computed: {
			tableData() {
				let tables = this.items;

				if (!tables.errno) {
					for (let i = 0; i < tables.length; i++) {
						tables[i].checked = false;
					}
					return tables;
				} else {
					return [];
				}
			},
		},
		async created() {
			this.setTableField();
			this.pointTableItem = await this.getPointListByMap();
			// this.setTableItems();
		},
		methods: {
			pointListSelect(row) {
				this.ptSelectRow.item.ptName = row.ptName;
				this.ptSelectRow.item.ptAddr = row.ptAddr;
				this.ptSelectRow.item.ptIdx = row.ptIdx;

				this.$bvModal.hide("pointListModal");
			},
			async getPointListByMap() {

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
					↓↓↓↓↓
				*/
				try {
					let result = await backEndApi.equipMap.getPointListByMap();
					return result.data;
				} catch (err) {
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.errHandling();
				}
			},
			setPointList(row) {
				this.ptSelectRow = row;
				this.$bvModal.show("pointListModal");
			},
			setTableField() {
				this.tableField = [
					{ key: "checked", label: "", tdClass: "pointDetailChecked" },
					{ key: "equipPropCode", label: global.xe.$t("항목 코드"), tdClass: "pointDetailPropCode" },
					{ key: "equipPropName", label: global.xe.$t("항목명"), tdClass: "pointDetailPropName" },
					{ key: "ptName", label: global.xe.$t("관제점"), tdClass: "pointDetailPtName" },
					{ key: "mapDetail", label: global.xe.$t("매핑 상세 설명"), tdClass: "pointDetailMapDetail" },
				];
			},

			onRowDetail(row) {
				row.checked = !row.checked;
			},
			isCheckService(value, row) {
				if (value === true) {
					this.$refs.EquipPointDetailTable.selectRow(row.index);
				} else if (value === false) {
					this.$refs.EquipPointDetailTable.unselectRow(row.index);
				}
			},
			isRowInsert() {
				this.tableData.push({
					checked: false,
					equipTypeCode: this.equipTypeCode,
					equipPropCode: "",
					equipPropName: "",
					enabled: "N",
					sortOrder: "",
					propDesc: "",
					unitLargeCode: "",
					unitSmallCode: "",
				});

				if (!Array.isArray(this.emptyData)) {
					this.$emit("firstInput", this.tableData);
					this.$refs.EquipPointDetailTable.refresh();
				}
			},
			onSearch(data) {
				if (data.zoneIdx === null && data.useage === null && data.pointName === null) {
					this.pointTableFilering = null;
					return;
				}

				this.pointTableFilering = this.pointTableItem.filter((item) => {
					if (data.zoneIdx !== null && data.zoneIdx !== item.applyZoneIdx) {
						return false;
					}
					if (data.useage !== null && data.useage !== item.itemUseageCode) {
						return false;
					}
					if (data.pointName !== null && item.ptName.includes(data.pointName) !== true) {
						return false;
					}
					return true;
				});

				// if (data.zoneIdx !== null) {
				//   this.pointTableFilering = this.pointTableItem.filter((item) => item.applyZoneIdx === data.zoneIdx);
				// }

				// if (data.useage !== null) {
				//   this.pointTableFilering = this.pointTableItem.filter((item) => item.itemUseageCode === data.useage);
				// }

				// if (data.pointName !== null) {
				//   this.pointTableFilering = this.pointTableItem.filter((item) => item.ptName === data.pointName);
				// }
			},
		},
	};
</script>

<style lang="scss">
	.EquipPointDetailTable {
		.pointDetailPtName {
			input {
				cursor: pointer;
			}
		}

		.table {
			td {
				vertical-align: middle;
			}
		}

		.rowInsertButton {
			text-align: center;
			margin-top: 10px;
		}
	}
</style>
